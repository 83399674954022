import React, { useMemo, useRef, useState } from 'react';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';
import { Flex, Box, Text } from 'rebass';
import { Query } from '@apollo/client/react/components';
import NumberFormat from 'react-number-format';
import { Element, scroller } from 'react-scroll';
import moment from 'moment';

import Link from '~common/atoms/Link';
import Icon from '~common/atoms/Icon';
import Popover from 'components/Elements/Popover';
import Block from '~common/atoms/Block';
import ToggleSwitch from 'components/Elements/ToggleSwitch';

import FAQBrowser from 'components/Products/FAQBrowser';

import { RatesToggle } from '~lib/CoverProducts';
import { formatToISO8601DateOnly } from '~lib/dates';
import { ProductQuoteQuery } from 'queries/quote.graphql';
import { Caption, H1, H2, H3, P1, P2, P3 } from '~common/atoms/typography';
import { useThemeUI } from 'theme-ui';
import ProductSwitch from './ProductSwitch';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';
import { Button } from '~common/atoms/Button';
import { ProductCards } from '../../components/PageBlocks';
import DiscountBar from '~common/molecules/DiscountBar';
import styled from '@emotion/styled';
import InclusionsTable from '../../components/Products/InclusionsTable';
import InfoIcon from '~common/molecules/InfoIcon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { useMobileScreenOnly } from '~lib/responsive';
import { List, ListItem } from '~common/atoms/List';
import useRatesToggle from '~lib/hooks/useRatesToggle';

const TermSelect = styled.select`
  margin: 10px 0 0 0;
  width: auto;
  border: none;
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.neutral500} !important;
  text-align: right;
  line-height: 1.3;
  font-weight: 500;
  background: transparent;
  outline: none;
`;

const ProductContainer = styled.div`
  background: ${props => props.theme.colors.background.white};
`;

const ProductBody = styled(Box)`
  position: absolute;
  top: 323px;
}
  ${props => props.theme.mq.tabletP} {
    position: inherit;
    top: unset;
  }
`;

const scrollTo = name => {
  scroller.scrollTo(name, {
    duration: 1000,
    delay: 0,
    isDynamic: true,
    offset: 0,
    smooth: 'easeInOutQuint',
  });
};

const SectionContainer = styled(Flex)`
  line-height: 40px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.neutral50};
  border-radius: 4px;
`;

const InclusionTableTitle = ({ text, icon, ...props }) => {
  return (
    <Flex
      alignItems="center"
      mt={{ xs: '28px', lg: '0' }}
      px={padding}
      mb="24px"
      {...props}
    >
      <Icon {...icon} height="23px" width="23px" mr="13px" />
      <H3 variant="semiBold" color="neutral800">
        {text}
      </H3>
    </Flex>
  );
};

const Section = ({ text, icon, ...props }) => {
  return (
    <SectionContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      p="12px"
      bg="white"
      onClick={() => scrollTo(text?.toLowerCase())}
      {...props}
    >
      <Flex justifyContent="flex-start" alignItems="center">
        <Icon
          {...icon}
          width="17px"
          height="17px"
          style={{ marginRight: '10px' }}
        />{' '}
        <P2 variant="semiBold" color="neutral500">
          {text}
        </P2>
      </Flex>
    </SectionContainer>
  );
};

const SectionLinks = ({ types, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <Box {...props}>
      <P1 variant="semiBold" mb="16px">
        Jump to
      </P1>
      {!types || types.includes('included') || types.includes('included-p') ? (
        <Section
          text="Inclusions"
          icon={{
            name: 'circle-fill-tick',
            fill: theme.colors.green,
          }}
        />
      ) : null}
      {!types ||
      types.includes('restricted') ||
      types.includes('restricted-accident') ? (
        <Section
          text="Restrictions"
          icon={{
            name: 'restricted',
            fill: theme.colors.warning,
          }}
        />
      ) : null}
      {!types || types.includes('excluded') ? (
        <Section
          text="Exclusions"
          icon={{
            name: 'remove',
            fill: theme.colors.error500,
          }}
        />
      ) : null}
    </Box>
  );
};

const ProductSummary = styled(SanityRichTextContent)`
  margin-bottom: 24px;
  width: auto;
  ${props => props.theme.mq.lg} {
    width: 696px;
  }
  * {
    color: ${props => props.theme.colors.neutral500};
    font-size: ${props => props.theme.fontSizes[3]}px;
    line-height: 1.4;
    font-weight: 500;
  }
`;

const padding = {
  xs: 15,
  tabletP: 0,
};

const Product = props => {
  const { product, products, pageContext, footer } = props;
  const isMobile = useMobileScreenOnly();
  const ratesToggle = useRatesToggle();

  const sameTypeProducts = products
    .filter(productItem => productItem.content.type === product.content.type)
    .slice(0, 3);

  const { next, prev } = useMemo(() => {
    const currentIndex = sameTypeProducts.findIndex(
      p => p.content.slug?.current === product.content.slug?.current
    );
    return {
      next: sameTypeProducts[currentIndex + 1],
      prev: sameTypeProducts[currentIndex - 1],
    };
  }, [product?.content?.slug, sameTypeProducts]);

  const onSaleProducts = product.content.variants.filter(v => v.onSale);
  const { theme } = useThemeUI();
  const [quoteInput, setQuoteInput] = useState({
    status: 'SINGLE',
    state: 'NSW',
    dob: formatToISO8601DateOnly(
      moment().startOf('year').subtract(29, 'years')
    ),
    paymentFrequency: 'MONTHLY',
    resultType: product.content.resultType,
    [`${product.content.type}Product`]: onSaleProducts.length
      ? onSaleProducts[0].code
      : null,
  });

  const [selectedFAQ, setSelectedFAQ] = useState(null);

  const variant = product.content.variants.find(
    v => v.onSale && v.code === quoteInput.hospitalProduct
  );
  const hasExcess = !!(variant && variant.excess);
  const variants = product.content.variants.filter(v => {
    if (!v.onSale) {
      return false;
    }

    if (v.classes && v.classes.length) {
      return v.classes.includes(quoteInput.status);
    }

    return true;
  });

  const excessVariants = {
    display: variants.length > 1 && variants.some(v => v.excess),
  };

  if (excessVariants.display) {
    excessVariants.values = variants
      .sort((a, b) => {
        if (a.excess < b.excess) {
          return -1;
        }
        if (a.excess > b.excess) {
          return 1;
        }
        return 0;
      })
      .map(v => {
        return {
          label: v.excess ? `$${v.excess}` : '$0',
          value: v.code,
        };
      });
  }

  const onVariantChange = code => {
    setQuoteInput(quote => {
      const selectedVariant = product.content.variants.find(
        v =>
          v.onSale && v.code === code && v.classes.includes(quoteInput.status)
      );

      if (!selectedVariant) {
        return quote;
      }

      return {
        ...quote,
        [`${product.content.type}Product`]: selectedVariant.code,
      };
    });
  };

  const inclusionTypes = product
    ? ['included', 'restricted', 'excluded'].filter(type =>
        product.content.inclusions.find(
          i => i.type.toLowerCase().indexOf(type) >= 0
        )
      )
    : [];

  const otherProductsRef = useRef(null);

  const factsheet = product ? product.content.factSheet : null;

  const onClick = q => {
    if (q.link) {
      navigate(q.link);
      return;
    }
    setSelectedFAQ(q.title);
    // Slight delay to allow the accordion to open, otherwise scroll position is wrong
    setTimeout(() => scrollTo(q.title), 200);
  };

  const bannerColor = theme.colors[product.content.type];

  return (
    <ProductContainer>
      <Block
        mb="48px"
        css={css`
          background: ${bannerColor};
          position: relative;
          height: 245px;
          ${theme.mq.tabletP} {
            height: 300px;
          }
        `}
      >
        <Flex
          alignItems="center"
          css={css`
            position: absolute;
            top: 10px;
          `}
        >
          <Box mr={2}>
            <Icon width="5px" fill={theme.colors.white} name="chevron-left" />
          </Box>
          <P3
            variant="semiBold"
            color="white"
            clickable
            css={css`
              text-decoration: none;
            `}
            as={Link}
            to={`/compare-${product.content.type}-products`}
          >
            Compare{' '}
            {product.content.type === 'hospital' ? 'Hospital' : 'Extras'}{' '}
            Products
          </P3>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <H1
            variant="semiBold"
            textAlign="left"
            color={theme.colors.white}
            width={{
              xs: '100%',
              lg: '65%',
            }}
          >
            {product.content.title}{' '}
            {product.content.type === 'hospital'
              ? product.content.coverage
              : ''}
          </H1>
        </Flex>
      </Block>
      <ProductBody>
        <Block
          innerProps={{
            maxWidth: '1200px',
            css: css`
              display: grid;
              grid-template-columns: 1fr;
              ${theme.mq.tabletL} {
                grid-template-columns: 1fr 337px;
                grid-gap: 20px;
              }
            `,
          }}
        >
          <Flex
            flexDirection={{ xs: 'column', lg: 'column' }}
            justifyContent="space-between"
            width="100%"
          >
            <Box mb={{ xs: '0', lg: 3 }} mt={0} px={padding}>
              <div>
                {isMobile ? (
                  <P1 mb="24px" color="neutral900" variant="medium" mt="40px">
                    {product.content.description}
                  </P1>
                ) : (
                  <H3
                    mb="24px"
                    color="neutral900"
                    variant="medium"
                    mt={{
                      xs: '40px',
                      tabletL: 0,
                    }}
                  >
                    {product.content.description}
                  </H3>
                )}
                <ProductSummary content={product.content.body} />
                <P1 mb="12px" variant="semiBold" color="neutral900">
                  Important Information
                </P1>
                <List color={theme.colors.primary}>
                  {product.content.faq && product.content.faq.length && (
                    <>
                      {product.content.faq.map((q, index) => (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <ListItem
                          key={`product-${index}`}
                          onClick={() => onClick(q)}
                        >
                          <P2
                            external
                            to="#"
                            as="button"
                            variant="semiBold"
                            as={Link}
                            color="primary"
                          >
                            {q.title}
                          </P2>
                        </ListItem>
                      ))}
                    </>
                  )}
                </List>
              </div>
              {inclusionTypes.includes('included') ? (
                <Element name="inclusions">
                  <InclusionTableTitle
                    mt={{
                      xs: 33,
                      tabletP: 73,
                    }}
                    text="Inclusions"
                    icon={{
                      name: 'circle-fill-tick',
                      fill: theme.colors.green,
                    }}
                  />

                  <InclusionsTable
                    resultType={quoteInput.resultType}
                    inclusions={product.content.inclusions}
                    productType={product.content.type}
                    type="included"
                  />

                  {product.content.inclusions.some(i => !!i.comparisonTier) ? (
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      mt="24px"
                    >
                      <Flex flexDirection="row" alignItems="center" mr={3}>
                        <Icon
                          originalColorSchema
                          name="silver-shield"
                          height="24px"
                          alt=""
                        />
                        <P2 color="neutral500" variant="medium" ml="5px">
                          Silver inclusion
                        </P2>
                      </Flex>
                      <Flex flexDirection="row" alignItems="center">
                        <Icon
                          originalColorSchema
                          name="gold-shield"
                          height="24px"
                          alt=""
                        />
                        <P2 ml="5px" color="neutral500" variant="medium">
                          Gold inclusion
                        </P2>
                      </Flex>
                    </Flex>
                  ) : null}
                </Element>
              ) : null}

              {inclusionTypes.includes('restricted') ? (
                <Element name="restrictions">
                  <InclusionTableTitle
                    mt="48px"
                    text="Restrictions"
                    icon={{
                      name: 'restricted',
                      fill: theme.colors.warning,
                    }}
                  />
                  <P2
                    textAlign="left"
                    color="neutral500"
                    variant="medium"
                    mb="14px"
                    mt={{ xs: '5px', lg: 0 }}
                    style={{ paddingBottom: '5px' }}
                  >
                    These services are limited to delivery as a private patient
                    in a public hospital in a shared room.
                  </P2>
                  <InclusionsTable
                    inclusions={product.content.inclusions}
                    productType={product.content.type}
                    type="restricted"
                  />
                </Element>
              ) : null}

              {inclusionTypes.includes('excluded') ? (
                <Element name="exclusions">
                  <InclusionTableTitle
                    mt="48px"
                    text="Exclusions"
                    icon={{
                      name: 'remove',
                      fill: theme.colors.error500,
                    }}
                  />

                  <InclusionsTable
                    inclusions={product.content.inclusions}
                    productType={product.content.type}
                    type="excluded"
                  />
                </Element>
              ) : null}

              {factsheet ? (
                <Flex>
                  <Button
                    size="medium"
                    as="a"
                    href={rewriteSanityUrl(factsheet.asset.url)}
                    target="_blank"
                    mt={{ xs: '20px', lg: '40px' }}
                    variant="primaryInverted"
                    icon="oms-download"
                  >
                    {product.content.title} Factsheet
                  </Button>
                </Flex>
              ) : null}
            </Box>

            <Box
              pb={{
                xs: 60,
                tabletP: 0,
              }}
              px={padding}
            >
              <H3
                variant="semiBold"
                color="neutral800"
                mt={{ xs: '40px', lg: '60px' }}
                mb="40px"
              >
                Things you should know
              </H3>
              <FAQBrowser
                product={product}
                selected={selectedFAQ}
                setSelected={setSelectedFAQ}
                css={css`
                  @media (min-width: 992px) {
                    margin-bottom: 100px;
                  }
                `}
              />
            </Box>
          </Flex>
          <Box
            px={{
              xs: 15,
              tabletP: 0,
            }}
            width={{ xs: '100%', lg: 'auto' }}
            css={css`
              height: max-content;
              ${theme.mq.lg} {
                position: sticky;
                top: 205px;
                transform: translateY(-205px);
              }

              @media (max-width: 1024px) {
                grid-row: 1;
              }
            `}
          >
            <Query
              query={ProductQuoteQuery}
              variables={{
                input: quoteInput,
              }}
              skip={!quoteInput.hospitalProduct && !quoteInput.extrasProduct}
            >
              {({ loading, error, data }) => {
                if (error) {
                  return `Error! ${error.message}`;
                }

                return (
                  <Box>
                    <RatesToggle
                      selected={quoteInput.resultType}
                      onAfter={() => {
                        setQuoteInput(currentInput => ({
                          ...currentInput,
                          resultType: 'FUTURE',
                        }));
                      }}
                      onBefore={() => {
                        setQuoteInput(currentInput => ({
                          ...currentInput,
                          resultType: 'CURRENT',
                        }));
                      }}
                      mt={{
                        xs: 50,
                        tabletP: 0,
                      }}
                      width={{
                        xs: 1,
                        tabletP: 'inherit',
                      }}
                    />

                    <Box
                      bg="white"
                      px="21px"
                      pt={20}
                      pb={30}
                      mt={{
                        xs: ratesToggle ? 24 : 50,
                        tabletP: ratesToggle ? 24 : 0,
                      }}
                      css={{
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <ProductSwitch next={next} prev={prev} pb={33} />
                      <Flex justifyContent="space-between">
                        <Box>
                          <H2
                            fontSize={9}
                            variant="semiBold"
                            color="neutral900"
                            textAlign="left"
                          >
                            {product.content.title}{' '}
                          </H2>
                          {product.content.type === 'hospital' ? (
                            <H2
                              mt="5px"
                              fontSize={9}
                              variant="semiBold"
                              color="neutral900"
                              textAlign="left"
                            >
                              {product.content.coverage}
                            </H2>
                          ) : null}
                        </Box>

                        <Flex flexDirection="column">
                          <H3
                            variant="semiBold"
                            textAlign="right"
                            color="neutral900"
                            mt={{ xs: '5px' }}
                          >
                            {loading ? null : (
                              <NumberFormat
                                value={data.quote.totals.net}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            )}
                            <sup>*</sup>
                          </H3>

                          <TermSelect
                            value={quoteInput.paymentFrequency}
                            onChange={e =>
                              setQuoteInput(current => ({
                                ...current,
                                paymentFrequency: e.target.value,
                              }))
                            }
                          >
                            <option value="MONTHLY">per month</option>
                            <option value="QUARTERLY">per quarter</option>
                            <option value="HALF_YEARLY">per 6 months</option>
                            <option value="YEARLY">per year</option>
                          </TermSelect>
                        </Flex>
                      </Flex>
                      {excessVariants.display ? (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="20px"
                        >
                          <Flex
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <P3
                              variant="medium"
                              mr="10px"
                              color="neutral500"
                              mb={{ xs: '10px', sm: 0 }}
                            >
                              Hospital excess
                            </P3>
                            <InfoIcon tooltip="This is the amount you agree to pay before your health insurance starts to pay for your hospital costs. Excess is paid per admission up to the yearly excess you have chosen. Consider whether you will be able to manage the cost of the excess if you go to hospital. An excess on health insurance will reduce its cost." />
                          </Flex>
                          <ToggleSwitch
                            id="total-excess"
                            ariaLabel="Excess"
                            defaultValue={variant.code}
                            values={excessVariants.values}
                            onChange={onVariantChange}
                          />
                        </Flex>
                      ) : hasExcess ? (
                        <Flex
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          mt="20px"
                        >
                          <Flex
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Text fontSize={0} color="text.dark">
                              Hospital excess
                            </Text>
                            <Popover
                              position="bottom"
                              content="This is the amount you agree to pay before your health insurance starts to pay for your hospital costs. Excess is paid per admission up to the yearly excess you have chosen. Consider whether you will be able to manage the cost of the excess if you go to hospital. An excess on health insurance will reduce its cost."
                            >
                              <Icon
                                name="help-circled"
                                width="16px"
                                height="16px"
                                fill="rgba(74, 74, 74, 0.6)"
                                style={{ marginLeft: '5px' }}
                              />
                            </Popover>
                          </Flex>
                          <ToggleSwitch
                            name={variant.code}
                            id={variant.code}
                            ariaLabel="Excess"
                            defaultValue={variant.code}
                            values={[
                              {
                                label: variant.excess
                                  ? `$${variant.excess}`
                                  : 'No excess',
                                value: variant.code,
                              },
                            ]}
                          />
                        </Flex>
                      ) : null}
                      <Button
                        size="medium"
                        my={3}
                        width={1}
                        style={{
                          textDecoration: 'none',
                        }}
                        variant={theme.quoteButtonStyle}
                        as={Link}
                        to={`/quote/?hospitalProduct=${quoteInput.hospitalProduct}&extrasProduct=${quoteInput.extrasProduct}&paymentFrequency=${quoteInput.paymentFrequency}&resultType=${quoteInput.resultType}`}
                      >
                        Get a quote
                      </Button>
                      <Caption
                        variant="medium"
                        color="neutral900"
                        textAlign="left"
                        mt={2}
                      >
                        *This premium is for a single in NSW that includes the
                        full Australian Government Rebate of{' '}
                        {loading ? null : (
                          <NumberFormat
                            value={data.quote.rebatePercentage}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={'%'}
                            decimalScale={3}
                          />
                        )}
                        .
                      </Caption>
                      <Flex justifyContent="flex-start" alignItems="center">
                        <DiscountBar
                          color={theme.colors.primary}
                          onClick={() =>
                            setQuoteInput(current => ({
                              ...current,
                              paymentFrequency: 'YEARLY',
                            }))
                          }
                          paymentFrequency={quoteInput.paymentFrequency}
                        />
                      </Flex>
                    </Box>
                  </Box>
                );
              }}
            </Query>
            <SectionLinks types={inclusionTypes} mt={4} />
          </Box>
        </Block>
        <ProductCards
          type="dark"
          data={
            product.content.type === 'hospital'
              ? {
                  ...pageContext.cards,
                  title: 'Extra protected with Extras Cover',
                }
              : {
                  ...pageContext.cards,
                  title: 'You might also be interested in Hospital Cover',
                }
          }
          ref={otherProductsRef}
          mb={0}
          py={{ xs: 4, lg: 80 }}
          style={{ position: 'relative', zIndex: '50' }}
        />
        {footer}
      </ProductBody>
    </ProductContainer>
  );
};

export default Product;
